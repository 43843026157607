import { BaseEntity } from 'processdelight-angular-components';

export class ProjectMVP extends BaseEntity {
  title!: string;
  projectId!: string;

  constructor(obj: Partial<ProjectMVP>) {
    super(obj);
    Object.assign(this, obj);
  }
}
