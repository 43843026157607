<processdelight-action-bar
  [iconActions]="iconAction"
  [navColor]="navColor || 'rgb(18, 68, 100)'"
  [navContrast]="navContrast || 'white'"
  [buttonActions]="buttons"
>
</processdelight-action-bar>
<div class="wrapper">
  <div class="sidebar">
    <div class="titlebar">
      <h5 class="text-center m-3">{{translations.chartSettings}}</h5>
      <mat-spinner *ngIf="loading" class="smallspinner"></mat-spinner>
    </div>
    <div class="sidebarcontent">
      <mat-card
        [style.background-color]="getBackgroundColor(page.id)"
        *ngFor="let page of pages"
      >
        <div class="card" (click)="selectPage(page.id)">
          <div class="icon" (click)="selectPage(page.id)">
            <mat-icon [style.color]="getTextColor(page.id)">{{
              page.icon
            }}</mat-icon>
          </div>
          <span [style.color]="getTextColor(page.id)">{{ page.label }}</span>
        </div>
      </mat-card>
    </div>
  </div>
  <mat-divider [vertical]="true"> </mat-divider>
  <div class="content" *ngIf="currentAppConfig">
    <div *ngIf="isSelected('defaults')" [ngTemplateOutlet]="defaults"></div>
    <div *ngIf="isSelected('colors')" [ngTemplateOutlet]="colors"></div>
  </div>
</div>

<ng-template #defaults>
  <div class="contentwrapper" *ngIf="currentAppConfig">
    <div class="checkbox">
      <p>{{translations.default}}</p>
    </div>
  </div>
</ng-template>

<ng-template #colors>
  <div class="contentwrapper" *ngIf="currentAppConfig">
    <div class="colorpicker">
      <p>{{translations.taskColor}}</p>
      <div>
        <input
          [(colorPicker)]="currentAppConfig.actualTaskColor"
          [style.background]="currentAppConfig.actualTaskColor"
          [cpPresetColors]="presetColors"
        />
      </div>
    </div>
    <div class="colorpicker">
      <p>{{translations.estimatedTaskColor}}</p>
      <div>
        <input
          [(colorPicker)]="currentAppConfig.estimatedTaskColor"
          [style.background]="currentAppConfig.estimatedTaskColor"
          [cpPresetColors]="presetColors"
        />
      </div>
    </div>
    <div class="colorpicker">
      <p>{{translations.projectColor}}</p>
      <div>
        <input
          [(colorPicker)]="currentAppConfig.projectColor"
          [style.background]="currentAppConfig.projectColor"
          [cpPresetColors]="presetColors"
        />
      </div>
    </div>
    <div class="colorpicker">
      <p>{{translations.progressColor}}</p>
      <div>
        <input
          [(colorPicker)]="currentAppConfig.progressColor"
          [style.background]="currentAppConfig.progressColor"
          [cpPresetColors]="presetColors"
        />
      </div>
    </div>
    <div class="colorpicker">
      <p>{{translations.earlyFinishColor}}</p>
      <div>
        <input
          [(colorPicker)]="currentAppConfig.earlyColor"
          [style.background]="currentAppConfig.earlyColor"
          [cpPresetColors]="presetColors"
        />
      </div>
    </div>
    <div class="colorpicker">
      <p>{{translations.lateFinishColor}}</p>
      <div>
        <input
          [(colorPicker)]="currentAppConfig.lateColor"
          [style.background]="currentAppConfig.lateColor"
          [cpPresetColors]="presetColors"
        />
      </div>
    </div>
  </div>
</ng-template>
