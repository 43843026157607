<processdelight-top-layout
  [sideBarActions]="sideBarActions"
  [buttonActions]="(buttonActions | async) || []"
  [iconActions]="iconActions"
  [navColor]="(navColor$ | async) || 'rgb(18, 68, 100)'"
  [navContrast]="(navContrast$ | async) || 'white'"
  (search)="search($event)"
  [appLogo]="appLogo || ''"
  [navColor]="(navColor$ | async) || 'rgb(18, 68, 100)'"
>
  <router-outlet></router-outlet>
</processdelight-top-layout>
