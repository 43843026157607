import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Routes,
} from '@angular/router';
import { MicrosoftAuthenticationGuard } from 'processdelight-angular-components';
import { PreventNavigationGuard } from './guards/prevent-navigation.guard';
import { varlicense$ } from './services/startup.service';

export const canActivateAdmin = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  return (
    varlicense$.value?.licenses.some(
      (i) => i.productName == 'Ishtar.Resource' && i.isAdmin
    ) ?? false
  );
};

export const appRoutes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('processdelight-angular-components').then((m) => m.MsalModule),
  },
  {
    path: 'planning',
    canActivate: [MicrosoftAuthenticationGuard],
    loadComponent: () =>
      import('../components/planning/planning.component').then(
        (mod) => mod.PlanningComponent
      ),
    data: { breadcrumbTitle: 'Planning' },
  },
  {
    path: 'settings',
    canActivate: [MicrosoftAuthenticationGuard, canActivateAdmin],
    loadComponent: () =>
      import('../components/settings/settings.component').then(
        (mod) => mod.SettingsComponent
      ),
    data: { breadcrumbTitle: 'Settings' },
    loadChildren: () =>
      import('../components/settings/settings.component').then(
        (comp) => comp.settingsRoutes
      ),
  },
  {
    path: '**',
    redirectTo: 'planning',
  },
];
