import { BaseEntity } from 'processdelight-angular-components';

export class Status extends BaseEntity {
  status!: string;
  color?: string;
  isFinalState?: boolean;
  inShortMenu?: boolean;
  isDeleted?: boolean;
  sortNumber?: number;

  constructor(obj: Partial<Status>) {
    super(obj);
    Object.assign(this, obj);
  }
}
