import { Injectable } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { ResourcePopupComponent } from 'src/app/components/planning/resource-popup/resource-popup.component';
import { ResourceThingPopupComponent } from 'src/app/components/settings/resource-thing/resource-thing-popup/resource-thing-popup.component';
import { ResourceThing } from 'src/app/core/models/resource/resourceThing';
import { PreventNavigationPopupComponent } from './prevent-navigation-popup/prevent-navigation-popup.component';
import { GanttSettingsPopupComponent } from './gantt-settings-popup/gantt-settings-popup.component';

@Injectable({
  providedIn: 'root',
  deps: [MatDialogModule],
})
export class PopupService {
  constructor(private dialog: MatDialog) {}

  openPrevenNavigationPopup(callback?: (close: boolean) => void) {
    const dialogRef = this.dialog.open(PreventNavigationPopupComponent, {
      width: '350px',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((cancel: boolean) => {
      if (callback) callback(cancel);
    });
  }

  openResourceThingPopup(rt?: ResourceThing) {
    const dialogRef = this.dialog.open(ResourceThingPopupComponent, {
      width: '900px',
      data: rt,
      disableClose: true,
    });
  }

  openResourcePopup(rt?: string, callback?: (id: string) => void) {
    this.dialog.closeAll(); // prevent multiple popups when coming from other apps
    const dialogRef = this.dialog.open(ResourcePopupComponent, {
      width: '900px',
      height: '700px',
      data: rt,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((id: string) => {
      if (callback) callback(id);
    });
  }

  openSettingsPopup(callback?: (id: string) => void) {
    const dialogRef = this.dialog.open(GanttSettingsPopupComponent, {
      width: '900px',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((id: string) => {
      if (callback) callback(id);
    });
  }
}
