import { BaseEntity } from 'processdelight-angular-components';
import { DateTime } from 'luxon';
import { IProjectTask } from './project-task.interface';
import { GroupUserEntity } from '../group-user-entity';

export class Task extends BaseEntity implements IProjectTask {
  title!: string;
  number!: number;
  description?: string;
  startTime?: DateTime;
  endTime?: DateTime;
  actualStartTime?: DateTime;
  actualEndTime?: DateTime;
  deadline?: DateTime;
  progress?: number;
  progressRegistrationTypeId?: string;
  parentTaskId?: string;
  projectId?: string;
  statusId?: string;
  priorityId?: string;
  skillId?: string;
  typeId?: string;
  isTaskTemplate?: boolean;
  subTasks: Task[] = [];
  estimatedTime?: number;
  completedTime?: number;
  isDeleted?: boolean;
  followers?: GroupUserEntity[];

  get startDate(): DateTime | undefined {
    return this.startTime;
  }

  calculatedStartDate?: DateTime;
  calculatedEndDate?: DateTime;

  expandable = false;
  level = 1;
  class: 'project' | 'task' | 'projectTask' = 'task';
  
  get subElements(): IProjectTask[] | undefined {
    return [...this.subTasks].sort((a, b) => a.number - b.number);
  }

  get users(): GroupUserEntity[] {
    return this.followers || [];
  }

  dependentOn?: string;
  dependencyCompletionValue?: number;

  subElementFilter?: (x: IProjectTask) => boolean;

  filterNode(search: string): boolean {
    const number = this.number ? `${this.number}` : null;
    return (
      this.title.toLowerCase().includes(search.toLowerCase()) 
        || this.users.some((u) => 
          u.displayName?.toLowerCase().includes(search.toLowerCase())
        )
        || number?.includes(search)
        || false
    );
  }

  containsUser(userId: string): boolean {
    return (
      this.users.some((u) => u.id === userId) 
        || this.subElements?.some((x) => x.containsUser(userId))
        || false
    );
  }

  constructor(obj: Partial<Task>) {
    super(obj);
    Object.assign(this, obj);

    if (obj['subTasks']) {
      this.subTasks = obj['subTasks']
        .map((x) => new Task({ ...x, subElementFilter: this.subElementFilter }))
        .filter(this.subElementFilter ? this.subElementFilter : (x) => true);
    }

    this.expandable = this.subTasks.length > 0;
  }
}
