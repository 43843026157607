import { BaseEntity } from 'processdelight-angular-components';

export class WorkRegime extends BaseEntity {
  userId!: string;
  capacity!: number;

  constructor(obj: Partial<WorkRegime>) {
    super(obj);
    Object.assign(this, obj);
  }
}
