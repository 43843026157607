import { BaseEntity } from 'processdelight-angular-components';
import { ResourceFunction } from './resourceFunction';

export class ResourceThing extends BaseEntity {
  name!: string;
  resourceFunctions?: ResourceFunction[];
  constructor(obj: Partial<ResourceThing>) {
    super(obj);
    Object.assign(this, obj);
  }
}
