import { Injectable, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  first,
  flatMap,
  forkJoin,
  map,
  startWith,
  Subject,
  switchMap,
  takeUntil,
  tap,
  timer,
} from 'rxjs';
import { LicenseInfo } from '../models/ishtar365/licenseinfo';
import { IshtarResourcePlanningService } from './resourceplanning.service';
import { AppInfo } from '../models/resource/appinfo';
import { TimeSort } from '../models/resource/timesort';
import { ResourceFacade } from '../store/resource/resource.facade';
import { TaskFacade } from '../store/task/task.facade';
import { Status } from '../models/task/status';
import {
  GroupUser,
  Ishtar365CommunicationService,
  MicrosoftAuthenticationService,
  TranslationService,
} from 'processdelight-angular-components';
import { ActivatedRoute, Router } from '@angular/router';
import { TaskRegistrationType } from '../models/task/registration-type';
import { ProjectType } from '../models/task/project-type';

export const varlicense$ = new BehaviorSubject<LicenseInfo | undefined>(undefined);
export const isAdmin = (ishtarApp = 'Ishtar.Resource') =>
  varlicense$.pipe(
    filter((license) => !!license),
    map((license) =>
      license?.licenses.some((l) => l.productName === ishtarApp && l.isAdmin)
    ),
    startWith(false)
  );

export const appInfo$ = new BehaviorSubject<AppInfo | undefined>(undefined);
export const taskUsers$ = new BehaviorSubject<GroupUser[]>([]);
export const resourceUsers$ = new BehaviorSubject<GroupUser[]>([]);
export const timeSorts$ = new BehaviorSubject<TimeSort[]>([]);
export const taskRegistrationTypes$ = new BehaviorSubject<TaskRegistrationType[]>([]);
export const projectTypes$ = new BehaviorSubject<ProjectType[]>([]);
export const statusses$ = new BehaviorSubject<Status[]>([]);

@Injectable({
  providedIn: 'root',
})
export class StartUpService implements OnDestroy {
  destroy$ = new Subject<void>();
  
  constructor(
    private ishtarResourcePlanningService: IshtarResourcePlanningService,
    private resFac: ResourceFacade,
    private taskFac: TaskFacade,
    private translationService: TranslationService,
    private i365com: Ishtar365CommunicationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private msal: MicrosoftAuthenticationService
  ) { }

  getLicense() {
    this.ishtarResourcePlanningService
      .getLicense(this.msal.tenantId)
      .pipe(first())
      .subscribe((data: any) => {
        varlicense$.next(data);
      });
  }

  getAppInfo() {
    return this.ishtarResourcePlanningService.getAppInfo().pipe(
      first(),
      tap((data) => {
        appInfo$.next(data);
      })
    );
  }

  getTypes() {
    this.ishtarResourcePlanningService
      .getTypes()
      .pipe(first())
      .subscribe((data) => {
        projectTypes$.next(data.projectTypes);
        taskRegistrationTypes$.next(data.registrationTypes);
        taskUsers$.next(data.taskUsers || []);
        resourceUsers$.next(data.resourceUsers);
        statusses$.next(data.statusses);
        timeSorts$.next(data.timeSorts);
      });
  }

  getTranslations(lang: string) {
    return this.ishtarResourcePlanningService.getTranslations(lang).pipe(
      first(),
      tap((data) => {
        this.translationService.update(data);
      })
    );
  }

  boot() {
    this.getLicense();
    timer(15 * 60 * 1000, 15 * 60 * 1000)
      .pipe(
        takeUntil(this.destroy$),
        filter(() => this.msal.signedIn.value),
        switchMap(() => this.ishtarResourcePlanningService.sessionKeepAlive())
      )
      .subscribe();
    return varlicense$.pipe(
      filter((license) => !!license),
      first(),
      switchMap(() => {
        try {
          this.i365com.init();
          this.i365com.registerRedirectAction('Task', (id) => {
            const segments = this.activatedRoute.snapshot.url.map(
              (s) => s.path
            );
            if (segments[0] !== 'planning') {
              this.router.navigate(['planning'], { queryParams: { task: id } });
            }
          });
        } catch (error) {
          // not in frame
        }
        this.getTypes();
        this.resFac.getAppConfig();
        this.resFac.getInitialLoad();
        return forkJoin([this.getTranslations(varlicense$.value?.language || ''), this.getAppInfo()]);
      })
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
