import { DateTime } from 'luxon';

export class PublicHoliday {
  holidayType!: string;
  holidayDate!: DateTime;

  constructor(obj: Partial<PublicHoliday>) {
    Object.assign(this, obj);
  }
}
