import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DateTime } from 'luxon';

import {
  ContextMenuAction,
  SideBarAction,
} from 'processdelight-angular-components';
import { Subject, combineLatest, filter, first, map } from 'rxjs';
import { environment } from '../environments/environment';
import { PopupModule } from './components/popup/popup.module';
import { CoreModule } from './core.module';
import { isAdmin, varlicense$ } from './core/services/startup.service';
import { ResourceFacade } from './core/store/resource/resource.facade';
import { ActionBarService } from './core/services/action-bar.service';
import { TopLayoutComponent } from 'processdelight-angular-components';

@Component({
  standalone: true,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [CoreModule, PopupModule, TopLayoutComponent],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'IshtarResourcePlanning';
  static dateformat = 'DD/MM/YYYY';

  appLogo = environment.cdnAppIconUrl.replace('color', 'Blauw_Wit');

  orgLogo$ = varlicense$.pipe(map((o) => o?.logo));
  userName$ = varlicense$.pipe(map((u) => u?.name));
  userPhoto$ = varlicense$.pipe(map((u) => u?.photo));
  navColor$ = varlicense$.pipe(map((u) => u?.navColor));
  navContrast$ = varlicense$.pipe(map((u) => u?.navContrast));

  static navColor = '#3f51b5';
  static navContrast = '#fff';

  navActions: ContextMenuAction<unknown>[] = [
    new ContextMenuAction({
      label: 'Home',
      icon: 'home',
      iconOutline: true,
      action: () => this.routeTo(['planning']),
    }),
  ];

  constructor(
    private router: Router,
    private actionServce: ActionBarService,
    private resFac: ResourceFacade
  ) {}

  sideBarActions = [
    new SideBarAction({
      title: 'Home',
      icon: 'home',
      iconOutline: true,
      route: ['planning'],
    }),
  ];
  buttonActions = this.actionServce.navActions;
  iconActions: ContextMenuAction<unknown>[] = [];

  settingsPopupAction = new ContextMenuAction<unknown>({
    label: 'Color settings',
    icon: 'settings',
    iconOutline: true,
    action: () => this.routeTo(['settings', 'people']),
  });

  routeTo(route: string[]): void {
    this.router.navigate(route);
  }

  search(value: string) {
    this.actionServce.searchValue.next(value);
  }

  ngOnInit() {
    varlicense$.pipe(filter((l) => !!l)).subscribe((license) => {
      if (license?.navColor) AppComponent.navColor = license.navColor;
      if (license?.navContrast) AppComponent.navContrast = license.navContrast;
    });

    combineLatest([isAdmin('Ishtar.Resource'), this.resFac.appConfig$])
      .pipe(
        filter(([isAdmin, conf]) => !!conf && !!isAdmin && isAdmin),
        first()
      )
      .subscribe((isAdmin) => (this.iconActions = [this.settingsPopupAction]));

    const params = new URLSearchParams(location.search);
    if (params.has('appColor')) {
      this.appLogo = environment.cdnAppIconUrl.replace(
        'color',
        params.get('appColor')!
      );
    }
    const tabIconLink = document.querySelector(
      "link[rel~='icon']"
    ) as HTMLLinkElement;
    if (tabIconLink) {
      tabIconLink.href = this.appLogo;
    }
  }

  destroy$ = new Subject<void>();
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

export function parseEUDate(string: any) {
  if (typeof string === 'object') return string;
  return DateTime.fromFormat(string, 'dd/MM/yyyy HH:mm', { zone: 'utc' });
}

export function parseUTCDate(string: any) {
  if (typeof string === 'object') return string;
  return DateTime.fromISO(string, { zone: 'utc' });
}
