import { BaseEntity } from 'processdelight-angular-components';

export class ResourceUser extends BaseEntity {
  userId!: string;
  capacity!: number;
  isDeleted!: boolean;

  constructor(obj: Partial<ResourceUser>) {
    super(obj);
    Object.assign(this, obj);
  }
}
