import { BaseEntity } from 'processdelight-angular-components';

export class ResourceFunction extends BaseEntity {
  name!: string;
  resourceThingId?: string;
  description?: string;
  capacity?: number;
  timeSortId!: string;
  timeAmount?: number;

  constructor(obj: Partial<ResourceFunction>) {
    super(obj);
    Object.assign(this, obj);
  }
}
