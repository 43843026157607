import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  AADUser,
  ContextMenuAction,
  Ishtar365CommunicationService,
  LoaderService,
  TranslationService,
} from 'processdelight-angular-components';
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  map,
  of,
  startWith,
  switchMap,
  takeUntil,
} from 'rxjs';
import { CoreModule } from 'src/app/core.module';
import { WorkRegime } from 'src/app/core/models/ishtar365/workregime';
import { Resource } from 'src/app/core/models/resource/resource';
import { ResourceFunction } from 'src/app/core/models/resource/resourceFunction';
import { ResourceThing } from 'src/app/core/models/resource/resourceThing';
import { ResourceUser } from 'src/app/core/models/resource/resourceUser';
import { Task } from 'src/app/core/models/task/task';
import {
  statusses$,
  taskRegistrationTypes$,
  resourceUsers$,
  varlicense$,
} from 'src/app/core/services/startup.service';
import { ResourceFacade } from 'src/app/core/store/resource/resource.facade';
import { TaskFacade } from 'src/app/core/store/task/task.facade';
import { PopupService } from '../../popup/popup.service';
import { TaskRegistrationType } from 'src/app/core/models/task/registration-type';
import { TimeSort } from 'src/app/core/models/resource/timesort';
import { DateTime } from 'luxon';

type FilterObject = {
  operator: 'AND' | 'OR';
  filters: (FilterObject | string)[];
};
@Component({
  selector: 'app-resource-popup',
  standalone: true,
  imports: [CoreModule],
  templateUrl: './resource-popup.component.html',
  styleUrls: ['./resource-popup.component.scss'],
})
export class ResourcePopupComponent implements OnInit, OnDestroy {
  dateFormat = 'dd/MM/yyyy';

  translations: any;
  destroy$ = new Subject<void>();
  resourceUsers = new BehaviorSubject<ResourceUser[]>([]);
  functions = new BehaviorSubject<ResourceFunction[]>([]);
  resources = new BehaviorSubject<Resource[]>([]);
  tasks = new BehaviorSubject<Task[]>([]);
  flattasks = new BehaviorSubject<Task[]>([]);
  resourceThings = new BehaviorSubject<ResourceThing[]>([]);
  registrationTypes = new BehaviorSubject<TaskRegistrationType[]>([]);
  workRegimes = new BehaviorSubject<WorkRegime[]>([]);
  timeSorts = new BehaviorSubject<TimeSort[]>([]);

  filteredfunctions = new BehaviorSubject<ResourceFunction[]>([]);

  taskForm = new FormControl('', [Validators.required]);

  taskComparator = (i: number, task: Task) => task.id || '';

  @ViewChild('userMachine', { read: ElementRef }) element:
    | ElementRef
    | undefined;
  @ViewChild('stepper') stepper!: MatStepper;
  stepperIndex = 0;

  userMachineToggle = new FormControl<boolean>(false);

  userArray = new FormArray<
    FormGroup<{
      selected: FormControl;
      new: FormControl;
      id: FormControl;
      title: FormControl;
      resourceUserId: FormControl;
      taskCapacity: FormControl;
      userCapacity: FormControl; // this is a temporary field
    }>
  >([]);

  filteredArray: any;

  resourceThingValues = new BehaviorSubject<ResourceThing[]>([]);
  activeThingForm = new FormControl('');
  activeMachineForm = new FormControl('', { validators: Validators.required });
  filterValue = new BehaviorSubject<string>('');

  showErrors = false;
  initialLoaded = false;
  tasksLoaded = false;
  taskStatusIsFinalised = false;

  enabledUserFormGroups$ = this.userArray.valueChanges.pipe(
    takeUntil(this.destroy$),
    switchMap((arr) => {
      return of(this.userArray).pipe(
        map((group) => group.controls.filter((g) => g.value.selected))
      );
    })
  );

  usergroupsList$ = combineLatest([
    this.userArray.valueChanges.pipe(startWith([])),
    this.filterValue,
  ]).pipe(
    debounceTime(300),
    takeUntil(this.destroy$),
    switchMap((arr) => {
      const userIds: string[] = [];
      this.resourceThingFacade.resourceUsers$.subscribe((u) => {
        u?.forEach((ru) => {
          userIds.push(ru.userId);
        });
      });

      const filteringList = this.searchUsers(this.filterValue.value, userIds);

      return of(this.userArray).pipe(
        map((group) =>
          group.controls.filter((g: FormGroup) => {
            const title = g.value.title.toLowerCase();
            return filteringList.some((userId) => {
              const displayName = resourceUsers$.value?.find((u) => u.user?.id === userId)?.displayName || '';
              return displayName.toLowerCase().includes(title)
            });
          })
        )
      );
    })
  );

  taskGroup = new FormGroup<{
    id: FormControl;
    name: FormControl;
    completedTime: FormControl;
    deadline: FormControl;
    description: FormControl;
    startTime: FormControl;
    endTime: FormControl;
    estimatedTime: FormControl;
    isDeleted: FormControl;
    isTaskTemplate: FormControl;
    number: FormControl;
    parentTaskId: FormControl;
    priorityId: FormControl;
    progress: FormControl;
    progressRegistrationTypeId: FormControl;
    projectId: FormControl;
    skillId: FormControl;
    statusId: FormControl;
    status: FormControl;
    typeId: FormControl;
  }>({
    id: new FormControl(''),
    name: new FormControl(''),
    completedTime: new FormControl(''),
    deadline: new FormControl(''),
    description: new FormControl(''),
    startTime: new FormControl(''),
    endTime: new FormControl(''),
    estimatedTime: new FormControl(''),
    isDeleted: new FormControl(''),
    isTaskTemplate: new FormControl(''),
    number: new FormControl(''),
    parentTaskId: new FormControl(''),
    priorityId: new FormControl(''),
    progress: new FormControl(''),
    progressRegistrationTypeId: new FormControl(''),
    projectId: new FormControl(''),
    skillId: new FormControl(''),
    statusId: new FormControl(''),
    status: new FormControl(''),
    typeId: new FormControl(''),
  });

  completed = new BehaviorSubject<boolean>(false);

  navColor$ = varlicense$.pipe(map((u) => u?.navColor));
  navContrast$ = varlicense$.pipe(map((u) => u?.navContrast));
  navButtons = this.completed.pipe(
    takeUntil(this.destroy$),
    map((d) => [
      new ContextMenuAction({
        label: this.translations.save,
        disabled: !d,
        action: () => {
          this.saveResource();
        },
      }),
      new ContextMenuAction({
        label: this.translations.next,
        disabled: !d,
        action: () => {
          this.nextPage();
        },
      }),
      new ContextMenuAction({
        label: this.translations.goToIshtarTasks,
        icon: 'open_in_new',
        action: () => {
          this.goToTasks();
        },
      }),
    ])
  );

  iconActions: ContextMenuAction<unknown>[] = [
    new ContextMenuAction<unknown>({
      label: 'Color settings',
      icon: 'close',
      iconOutline: true,
      action: () => {
        this.close();
      },
    }),
  ];

  totalControl = new FormGroup([this.userArray, this.activeMachineForm]);

  constructor(
    private resourceThingFacade: ResourceFacade,
    private taskFac: TaskFacade,
    private popup: PopupService,
    private router: Router,
    private route: ActivatedRoute,
    private dialogRef: MatDialogRef<ResourcePopupComponent>,
    private loader: LoaderService,
    private translation: TranslationService,
    private ishtar365com: Ishtar365CommunicationService,
    @Inject(MAT_DIALOG_DATA) rt: string | undefined
  ) {
    this.translations = translation.translations;
    this.setTaskIdPathParam(rt);
    this.taskForm.setValue(rt || '', { emitEvent: false });

    combineLatest([this.tasks, this.resources])
      .pipe(
        filter(([t, r]) => t.length != 0 && r.length != 0),
        first()
      )
      .subscribe(([t, r]) => {
        this.updateForm(rt || '');
      });

    this.taskGroup.controls.startTime.disable();
    this.taskGroup.controls.endTime.disable();
    this.taskGroup.controls.deadline.disable();
    this.taskGroup.controls.estimatedTime.disable();
    this.taskGroup.controls.completedTime.disable();
    this.taskGroup.controls.progressRegistrationTypeId.disable();
    this.taskGroup.controls.statusId.disable();
    this.taskGroup.controls.status.disable();
  }

  ngOnInit(): void {
    combineLatest([
      this.resourceThingFacade.resourceUsers$,
      this.taskFac.workRegimes$,
    ])
      .pipe(
        takeUntil(this.destroy$),
        filter(([u, w]) => !!u && !!w)
      )
      .subscribe(([u]) => {
        this.resourceUsers.next(u!);
        while (this.userArray.length !== 0) {
          this.userArray.removeAt(0);
        }
        u?.forEach((ru) => {
          this.userArray.push(
            new FormGroup({
              selected: new FormControl(false),
              new: new FormControl(true),
              id: new FormControl(''),
              resourceUserId: new FormControl(ru.id || ''),
              title: new FormControl(resourceUsers$.value?.find((u) => u.user?.id === ru.userId)?.user?.displayName || ''),
              taskCapacity: new FormControl(0),
              userCapacity: new FormControl(
                this.getUserCap(ru.id || '')
              ),
            })
          );
        });
        this.userArray.markAsPristine();
        this.userArray.markAsUntouched();
        this.updateResourceSelector();
      });

    combineLatest([
      this.userArray.valueChanges,
      this.activeMachineForm.valueChanges.pipe(startWith('')),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([users, machine]) => {
        const valid = users.some((u) => u.selected) || machine !== '';
        this.completed.next(valid);
      });

    this.resourceThingFacade.resourceFunctions$
      .pipe(
        takeUntil(this.destroy$),
        filter((f) => !!f)
      )
      .subscribe((f) => this.functions.next(f!));

    combineLatest([
      this.functions,
      this.activeThingForm.valueChanges,
      this.filterValue,
    ])
      .pipe(
        takeUntil(this.destroy$),
        map(([f, val, value]) => {
          const filter1 = f.filter((func) =>
            val !== ''
              ? func.resourceThingId === val
              : func.resourceThingId == undefined
          );
          return this.searchFunctions(value, filter1);
        })
      )
      .subscribe((f) => this.filteredfunctions.next(f));

    this.taskFac.tasks$
      .pipe(
        takeUntil(this.destroy$),
        filter((t) => !!t)
      )
      .subscribe((t) => {
        this.tasks.next(t!);
        this.tasksLoaded = true;
      });

    this.taskFac.tasksFlat$
      .pipe(
        takeUntil(this.destroy$),
        filter((t) => !!t)
      )
      .subscribe((t) => {
        this.flattasks.next(t!);
      });

    this.resourceThingFacade.resources$
      .pipe(
        takeUntil(this.destroy$),
        filter((r) => !!r)
      )
      .subscribe((r) => {
        this.resources.next(r!);
        this.updateResourceSelector();
        this.initialLoaded = true;
      });

    this.resourceThingFacade.resourceThings$
      .pipe(
        takeUntil(this.destroy$),
        filter((r) => !!r)
      )
      .subscribe((r) => this.resourceThings.next(r!));

    this.resourceThings
      .pipe(
        takeUntil(this.destroy$),
        map((t) =>
          t.concat([
            new ResourceThing({ name: 'Others', id: '' }),
          ])
        )
      )
      .subscribe((t) => this.resourceThingValues.next(t));

    taskRegistrationTypes$
      .pipe(
        takeUntil(this.destroy$),
        filter((t) => !!t)
      )
      .subscribe((t) => {
        this.registrationTypes.next(t!);
      });

    this.taskFac.workRegimes$
      .pipe(
        takeUntil(this.destroy$),
        filter((t) => !!t)
      )
      .subscribe((t) => this.workRegimes.next(t!));

    this.resourceThingFacade.timeSorts
      .pipe(
        takeUntil(this.destroy$),
        filter((t) => !!t)
      )
      .subscribe((t) => this.timeSorts.next(t!));

    combineLatest([this.resources, this.resourceUsers])
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.updateResourceSelector());

    combineLatest([this.taskGroup.controls.statusId.valueChanges, statusses$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([v, statusses]) => {
        this.taskStatusIsFinalised =
          statusses?.find((s) => s.id === v)?.isFinalState || false;
      });
  }

  searchFunctions(
    value: string,
    functionList: ResourceFunction[]
  ): ResourceFunction[] {
    if (value.trim() === '') return functionList;

    const filterObject = this.subFilter(value);

    return this.filterFunctions(filterObject, functionList);
  }

  filterFunctions(
    filter: FilterObject | string,
    funtionArray: ResourceFunction[]
  ): ResourceFunction[] {
    return funtionArray.filter((f) => {
      const title = f.name.toLowerCase();

      if (typeof filter === 'string') {
        // Include user if it satisfies the filter
        return title.includes(filter.toLowerCase());
      } else {
        // Include user if it satisfies the filter
        const rootSatisfies =
          (filter as FilterObject).operator === 'AND'
            ? (filter as FilterObject).filters.every(
              (subFilter) => this.filterFunctions(subFilter, [f]).length > 0
            )
            : (filter as FilterObject).filters.some(
              (subFilter) => this.filterFunctions(subFilter, [f]).length > 0
            );

        return rootSatisfies;
      }
    });
  }

  searchUsers(value: string, userIds: string[]): string[] {
    if (value.trim() === '') return userIds;

    const filterObject = this.subFilter(value);

    return this.filterUsers(filterObject, userIds);
  }

  filterUsers(filter: FilterObject | string, userIds: string[]): string[] {
    return userIds.filter((userId) => {
      const displayName = resourceUsers$.value?.find((u) => u.user?.id === userId)?.user?.displayName || '';
      const title = displayName.toLowerCase();

      if (typeof filter === 'string') {
        // Include user if it satisfies the filter
        return title.includes(filter.toLowerCase());
      } else {
        // Include user if it satisfies the filter
        const rootSatisfies =
          (filter as FilterObject).operator === 'AND'
            ? (filter as FilterObject).filters.every(
              (subFilter) => this.filterUsers(subFilter, [userId]).length > 0
            )
            : (filter as FilterObject).filters.some(
              (subFilter) => this.filterUsers(subFilter, [userId]).length > 0
            );

        return rootSatisfies;
      }
    });
  }

  subFilter(value: string): FilterObject | string {
    value = this.removeOuterBrackets(value);

    if (!(/ AND /.test(value) || / OR /.test(value))) {
      return value;
    }

    const result: FilterObject = {
      operator: 'AND', // You can choose 'AND' or 'OR' based on your needs
      filters: [],
    };

    let next: string[] = [];
    let foundAND = false;

    if (/ AND (?![^()]*\))/.test(value)) {
      next = value.split(/ AND (?![^()]*\))/); // split on ' AND ' that is not inside parentheses
      foundAND = true;
    } else if (/ OR (?![^()]*\))/.test(value)) {
      next = value.split(/ OR (?![^()]*\))/); // split on ' OR ' that is not inside parentheses
    }

    if (!foundAND) {
      result.operator = 'OR';
    }

    for (const n of next) {
      if (!(n[0] === '(' && n[n.length - 1] === ')')) {
        result.filters.push(this.subFilter(n));
      } else {
        result.filters.push(this.subFilter(this.removeOuterBrackets(n))); // Convert the array to a string
      }
    }
    return result;
  }

  removeOuterBrackets(inputString: string): string {
    const bracketRegex = /^\(.*\)$/;

    if (bracketRegex.test(inputString)) {
      return inputString.slice(2, -2);
    }

    return inputString;
  }

  goToTasks(): void {
    this.ishtar365com.redirectToApp(
      'Ishtar.Tasks',
      'openTask',
      this.taskGroup.get('id')?.value ?? ''
    );
  }

  updateForm(taskId: string | undefined) {
    const t = this.tasks.value.find((t) => t.id === taskId);

    const deadline = t?.deadline
      ? DateTime.fromISO(t?.deadline.toString(), { zone: 'utc' })
      : undefined;
    const startTime = t?.startTime
      ? DateTime.fromISO(t?.startTime.toString(), { zone: 'utc' })
      : undefined;
    const endTime = t?.endTime
      ? DateTime.fromISO(t?.endTime.toString(), { zone: 'utc' })
      : undefined;

    const status =
      statusses$.value?.find((s) => s.id === t?.statusId);
    this.taskGroup.setValue({
      id: t?.id || '',
      name: t?.title || '',
      completedTime: t?.completedTime || '',
      deadline: deadline?.toFormat(this.dateFormat) || '',
      description: t?.description || '',
      startTime: startTime?.toFormat(this.dateFormat) || '',
      endTime: endTime?.toFormat(this.dateFormat) || '',
      estimatedTime: t?.estimatedTime || '',
      isDeleted: t?.isDeleted || '',
      isTaskTemplate: t?.isTaskTemplate || '',
      number: t?.number || '',
      parentTaskId: t?.parentTaskId || '',
      priorityId: t?.priorityId || '',
      progress: t?.progress || '',
      progressRegistrationTypeId: t?.progressRegistrationTypeId || '',
      projectId: t?.projectId || '',
      skillId: t?.skillId || '',
      statusId: t?.statusId || '',
      status: status?.status,
      typeId: t?.typeId || '',
    });

    this.taskStatusIsFinalised =
      statusses$.value?.find((s) => s.id === t?.statusId)?.isFinalState ||
      false;
  }

  updateResourceSelector() {
    const r = this.resources.value.find(
      (res) => res.taskId === this.taskForm.value && res.functionId != null
    );
    const f = this.functions.value.find(
      (func) =>
        func.id === r?.functionId
    );

    this.activeMachineForm.setValue(
      r?.functionId || ''
    );

    this.activeThingForm.setValue(f?.resourceThingId || '');

    this.userArray.controls.forEach((control) => {
      const r = this.resources.value.find(
        (res) => res.taskId === this.taskForm.value
          && res.resourceUserId === control.value.resourceUserId
      );
      control.patchValue({
        selected: r !== undefined || control.value.selected,
        new: r === undefined,
        id: r?.id,
        taskCapacity: r?.taskCapacity ? r.taskCapacity * 100 : 100,
        userCapacity: this.getUserCap(control.value.resourceUserId),
      });
    });
  }

  selectFunction(ru: ResourceFunction) {
    if (this.activeMachineForm.value === ru.id)
      this.activeMachineForm.setValue('');
    else this.activeMachineForm.setValue(ru.id || '');
  }

  getUserResource = (
    group: FormGroup<{
      selected: FormControl;
      new: FormControl;
      id: FormControl;
      title: FormControl;
      resourceUserId: FormControl;
      taskCapacity: FormControl;
      userCapacity: FormControl;
    }>
  ) => {
    return new Resource({
      id: group.value.id,
      title: group.value.title,
      taskId: this.tasks.value.find(
        (t) => t.id === this.taskForm.value
      )?.id,
      resourceUserId: group.value.resourceUserId,
      taskCapacity: group.value.taskCapacity / 100,
    });
  };

  savingResources = false;
  saveResource() {
    if (this.totalControl.errors) return;
    this.savingResources = true;
    const res = this.userArray.controls
      .filter((g) => g.value.selected)
      .map((group) => this.getUserResource(group));
    if (this.activeMachineForm.value !== '')
      res.push(
        new Resource({
          title: 'Function Resource',
          taskId: this.tasks.value.find(
            (t) => t.id === this.taskForm.value
          )?.id,
          resourceUserId: undefined,
          taskCapacity: 100,
          functionId: this.functions.value.find(
            (f) => f.id === this.activeMachineForm.value
          )?.id,
        })
      );
    const loaderSubj = new Subject<void>();
    this.loader.startLoading('Saving resources', () => loaderSubj);
    this.resourceThingFacade.updateResourcesForTask(this.taskForm.value!, res, () => {
      this.dialogRef.close();
      loaderSubj.next();
      loaderSubj.complete();
    });

    this.totalControl.markAsPristine();
  }

  getUserCap(resourceUserId: string): number {
    const resourceUser = this.resourceUsers.value.find(
      (r) => r.id === resourceUserId
    );
    const resourceCap = resourceUser?.capacity || 1;
    const totalcap =
      this.workRegimes.value.find(
        (r) => r.userId === resourceUser?.userId
      )?.capacity ?? 40;
    return resourceCap * totalcap;
  }

  getUserTotal(
    formGroup: FormGroup<{
      selected: FormControl<any>;
      new: FormControl<any>;
      id: FormControl<any>;
      title: FormControl<any>;
      resourceUserId: FormControl<string>;
      taskCapacity: FormControl<number>;
      userCapacity: FormControl<number>;
    }>
  ): number | undefined {
    if (!formGroup.value.selected) return undefined;
    const usercap = formGroup.value.userCapacity;
    const formcap = formGroup.value.taskCapacity
      ? formGroup.value.taskCapacity / 100
      : undefined;
    return usercap && formcap ? usercap * formcap : undefined;
  }

  setTaskIdPathParam(id: string | undefined | null) {
    if (!id) return;
    const queryParams: Params = { task: id };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams,
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }

  //// Configure task form

  getGroupUser(userGroup: FormGroup) {
    return this.resourceUsers.value.find(
      (r) => r.id === userGroup.value.resourceUserId
    );
  }

  getSelectedUserForms() {
    return this.userArray.controls.filter((g) => g.value.selected);
  }

  getSelectedMachine() {
    return this.functions.value.find(
      (f) => f.id === this.activeMachineForm.value
    );
  }

  isRegTypeSelected(RegTypeName: 'Ishtar.Time' | 'Manual' | 'Time') {
    const v = this.registrationTypes.value.find(
      (r) => r.id === this.taskGroup.controls.progressRegistrationTypeId.value
    );
    return v ? v.type === RegTypeName : false;
  }

  getUser(resourceUserId: string) {
    const resourceUser = this.resourceUsers.value.find(
      (r) => r.id === resourceUserId
    );
    const regime = this.workRegimes.value.find(
      (r) => r?.userId === resourceUser?.userId
    );
    return resourceUsers$.value?.find((u) => u.user?.id === regime?.userId);
  }

  getTotal() {
    return (
      this.userArray.controls
        .map((g) => this.getUserTotal(g))
        .filter((p) => !!p)
        .reduce((a, b) => a! + b!, 0)! +
      (this.getSelectedMachine()?.capacity || 0)
    );
  }

  getTimeSort(timeSortId: string) {
    return this.timeSorts.value?.find((ts) => ts.id === timeSortId);
  }

  search(value: string): void {
    this.filterValue.next(value);
  }

  ////

  nextPage(): void {
    this.stepper.next();
  }

  close() {
    if (!this.totalControl?.pristine)
      this.popup.openPrevenNavigationPopup((close) => {
        if (close) this.dialogRef.close(this.taskForm.value || null);
      });
    else this.dialogRef.close(this.taskForm.value || null);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();

    this.router.navigate([], {
      queryParams: {
        task: null,
      },
      queryParamsHandling: 'merge',
    });
  }
}
