import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';
import * as fromResourceThing from './resource/resource.reducer';
import * as fromTask from './task/task.reducer';
import { ResourceThingEffects } from './resource/resource.effects';
import { TaskEffects } from './task/task.effects';

export const featureKey = 'coreFeature';

export interface CoreState {
  [fromResourceThing.featureSlice]: fromResourceThing.State;
  [fromTask.featureSlice]: fromTask.State;
}

export const reducers = new InjectionToken<ActionReducerMap<CoreState>>(
  featureKey,
  {
    factory: () => ({
      [fromResourceThing.featureSlice]: fromResourceThing.Reducer,
      [fromTask.featureSlice]: fromTask.Reducer,
    }),
  }
);
export const effects = [ResourceThingEffects, TaskEffects];
