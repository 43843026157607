import { Component, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  MatDialogModule,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  TranslationFile,
  TranslationService,
} from 'processdelight-angular-components';
import { BehaviorSubject } from 'rxjs';
import { CoreModule } from 'src/app/core.module';

export class PreventNavigationPopupData {
  navigationSubject!: BehaviorSubject<boolean>;
  saveSubject!: BehaviorSubject<boolean>;
  formgroup!: FormGroup;
}

@Component({
  selector: 'app-prevent-navigation-popup',
  standalone: true,
  imports: [CoreModule],
  templateUrl: './prevent-navigation-popup.component.html',
  styleUrls: ['./prevent-navigation-popup.component.scss'],
})
export class PreventNavigationPopupComponent {
  translations: any;

  constructor(
    private dialogRef: MatDialogRef<PreventNavigationPopupComponent>,
    private translate: TranslationService,
    @Inject(MAT_DIALOG_DATA) data: PreventNavigationPopupData
  ) {
    Object.assign(this, data);
    this.translations = translate.translations;
  }

  confirm() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
