import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  addResourceThings,
  addResourceThingsResolved,
  getResourceThings,
  getResourceThingsResolved,
  removeResourceThings,
  removeResourceThingResolved,
  updateResourceThings,
  updateResourceThingsResolved,
  addResourceFunctions,
  addResourceFunctionsResolved,
  getResourceFunctions,
  getResourceFunctionsResolved,
  removeResourceFunctions,
  removeResourceFunctionsResolved,
  updateResourceFunctions,
  updateResourceFunctionsResolved,
  getResourceUsers,
  getResourceUsersResolved,
  addResourceUsers,
  addResourceUsersResolved,
  updateResourceUsers,
  updateResourceUsersResolved,
  deleteResourceUsers,
  deleteResourceUsersResolved,
  getResources,
  getResourcesResolved,
  addResources,
  addResourcesResolved,
  updateResourcesForTask,
  updateResourcesForTaskResolved,
  removeResources,
  removeResourcesResolved,
  getAppConfig,
  getAppConfigResolved,
  updateAppConfig,
  updateAppConfigResolved,
  getInitialLoad,
  getInitialLoadResolved,
  getTimeSorts,
  getTimeSortsResolved,
  getAllResourceThings,
  getAllResourceThingsResolved,
} from './resource.actions';
import { IshtarResourcePlanningService } from '../../services/resourceplanning.service';

@Injectable({ providedIn: 'root' })
export class ResourceThingEffects {
  constructor(
    private actions$: Actions,
    private ishtarResourceService: IshtarResourcePlanningService
  ) {}

  getAppConfig = createEffect(() =>
    this.actions$.pipe(
      ofType(getAppConfig),
      switchMap(({ callback }) =>
        this.ishtarResourceService.getConfig().pipe(
          switchMap((config) =>
            of(
              getAppConfigResolved({
                appconfig: config,
              })
            )
          ),
          tap(() => (callback ? callback() : undefined)),
          catchError((e) => [])
        )
      )
    )
  );

  getInitialLoad = createEffect(() =>
    this.actions$.pipe(
      ofType(getInitialLoad),
      switchMap(({ callback }) =>
        this.ishtarResourceService.getInitialLoad().pipe(
          switchMap((data) => of(getInitialLoadResolved(data))),
          tap(() => (callback ? callback() : undefined)),
          catchError((e) => [])
        )
      )
    )
  );

  updateAppConfig = createEffect(() =>
    this.actions$.pipe(
      ofType(updateAppConfig),
      switchMap(({ config, callback }) =>
        this.ishtarResourceService.updateConfig(config).pipe(
          switchMap((config) =>
            of(
              updateAppConfigResolved({
                appconfig: config,
              })
            )
          ),
          tap(() => (callback ? callback() : undefined)),
          catchError((e) => [])
        )
      )
    )
  );

  getResources = createEffect(() =>
    this.actions$.pipe(
      ofType(getResources),
      switchMap(({ callback }) =>
        this.ishtarResourceService.getResources().pipe(
          switchMap((resources) => [
            getResourcesResolved({
              resources,
            }),
          ]),
          tap(() => (callback ? callback() : undefined)),
          catchError((e) => [])
        )
      )
    )
  );

  addResources = createEffect(() =>
    this.actions$.pipe(
      ofType(addResources),
      switchMap(({ resources, callback }) =>
        this.ishtarResourceService.addResources(resources).pipe(
          switchMap((resources) => [
            addResourcesResolved({
              resources,
            }),
          ]),
          tap(() => (callback ? callback() : undefined)),
          catchError((e) => [])
        )
      )
    )
  );

  updateResourcesForTask = createEffect(() =>
    this.actions$.pipe(
      ofType(updateResourcesForTask),
      switchMap(({ taskId, resources, callback }) =>
        this.ishtarResourceService.updateResourcesForTask(taskId, resources).pipe(
          switchMap((resources) => [
            updateResourcesForTaskResolved({
              taskId,
              resources,
            }),
          ]),
          tap(() => (callback ? callback() : undefined)),
          catchError((e) => [])
        )
      )
    )
  );

  deleteResources = createEffect(() =>
    this.actions$.pipe(
      ofType(removeResources),
      switchMap(({ ids, callback }) =>
        this.ishtarResourceService.deleteResources(ids).pipe(
          switchMap((ids) => [
            removeResourcesResolved({
              ids,
            }),
          ]),
          tap(() => (callback ? callback() : undefined)),
          catchError((e) => [])
        )
      )
    )
  );

  getAllResourceThings = createEffect(() =>
    this.actions$.pipe(
      ofType(getAllResourceThings),
      mergeMap(({ callback, errorCallback }) =>
        this.ishtarResourceService.getAllResourceThings()
          .pipe(
            tap((response) => (callback ? callback(response) : undefined)),
            switchMap((resourceThings) => of(
              getAllResourceThingsResolved({ 
                resourceThings
              })
            )),
            catchError((e) => {
              errorCallback?.(e);
              return [];
            })
          )
      )
    )
  );

  getResourceThings = createEffect(() =>
    this.actions$.pipe(
      ofType(getResourceThings),
      mergeMap(({ orderBy, direction, filters, pageSize, page, resetPaging, callback, errorCallback }) =>
        this.ishtarResourceService.getResourceThings(orderBy, direction, filters, pageSize, page)
          .pipe(
            tap((response) => (callback ? callback(response.result) : undefined)),
            switchMap(({ result, totalRecordCount }) => of(
              getResourceThingsResolved({ 
                resourceThings: result,
                totalRecordCount,
                resetPaging
              })
            )),
            catchError((e) => {
              errorCallback?.(e);
              return [];
            })
          )
      )
    )
  );

  addResourceThings = createEffect(() =>
    this.actions$.pipe(
      ofType(addResourceThings),
      switchMap(({ resourceThings, callback }) =>
        this.ishtarResourceService.addResourceThings(resourceThings).pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((addedResourceThings) =>
            of(addResourceThingsResolved({ addedResourceThings }))
          ),
          catchError((e) => [])
        )
      )
    )
  );

  updateResourceThings = createEffect(() =>
    this.actions$.pipe(
      ofType(updateResourceThings),
      switchMap(({ resourceThings, callback }) =>
        this.ishtarResourceService.updateResourceThings(resourceThings).pipe(
          tap(() => (callback ? callback() : undefined)),
          switchMap((updatedResourceThings) =>
            of(updateResourceThingsResolved({ updatedResourceThings }))
          ),
          catchError((e) => [])
        )
      )
    )
  );

  removeResourceThings = createEffect(() =>
    this.actions$.pipe(
      ofType(removeResourceThings),
      switchMap(({ ids, callback }) =>
        this.ishtarResourceService.removeResourceThings(ids).pipe(
          switchMap((ids) =>
            of(removeResourceThingResolved({ ids }))
          ),
          tap(() => (callback ? callback() : undefined)),
          catchError((e) => [])
        )
      )
    )
  );

  getResourceFunctions = createEffect(() =>
    this.actions$.pipe(
      ofType(getResourceFunctions),
      switchMap(({ callback }) =>
        this.ishtarResourceService.getResourceFunctions().pipe(
          switchMap((resourceFunctions) =>
            of(
              getResourceFunctionsResolved({
                resourceFunctions,
              })
            )
          ),
          tap(() => (callback ? callback() : undefined)),
          catchError((e) => [])
        )
      )
    )
  );

  addResourceFunctions = createEffect(() =>
    this.actions$.pipe(
      ofType(addResourceFunctions),
      switchMap(({ resourceFunctions, callback }) =>
        this.ishtarResourceService.addResourceFunctions(resourceFunctions).pipe(
          switchMap((addedResourceFunctions) =>
            of(addResourceFunctionsResolved({ addedResourceFunctions }))
          ),
          tap(() => (callback ? callback() : undefined)),
          catchError((e) => [])
        )
      )
    )
  );

  updateResourceFunctions = createEffect(() =>
    this.actions$.pipe(
      ofType(updateResourceFunctions),
      switchMap(({ resourceFunctions, callback }) =>
        this.ishtarResourceService
          .updateResourceFunctions(resourceFunctions)
          .pipe(
            tap(() => (callback ? callback() : undefined)),
            switchMap((updatedResourceFunctions) =>
              of(updateResourceFunctionsResolved({ updatedResourceFunctions }))
            ),
            catchError((e) => [])
          )
      )
    )
  );

  removeResourceFunctions = createEffect(() =>
    this.actions$.pipe(
      ofType(removeResourceFunctions),
      switchMap(({ ids, callback }) =>
        this.ishtarResourceService.removeResourceFunctions(ids).pipe(
          switchMap((ids) =>
            of(removeResourceFunctionsResolved({ ids }))
          ),
          tap(() => (callback ? callback() : undefined)),
          catchError((e) => [])
        )
      )
    )
  );

  getResourceUsers = createEffect(() =>
    this.actions$.pipe(
      ofType(getResourceUsers),
      switchMap(({ filters,callback }) =>
        this.ishtarResourceService.getResourceUsers(filters).pipe(
          switchMap((users) => of(getResourceUsersResolved({ users }))),
          tap(() => (callback ? callback() : undefined)),
          catchError((e) => [])
        )
      )
    )
  );

  addResourceUsers = createEffect(() =>
    this.actions$.pipe(
      ofType(addResourceUsers),
      switchMap(({ users, callback }) =>
        this.ishtarResourceService.addResourceUsers(users).pipe(
          switchMap((users) => of(addResourceUsersResolved({ users }))),
          tap(() => (callback ? callback() : undefined)),
          catchError((e) => {
            console.log(e);
            return [];
          })
        )
      )
    )
  );

  updateResourceUsers = createEffect(() =>
    this.actions$.pipe(
      ofType(updateResourceUsers),
      switchMap(({ users, callback }) =>
        this.ishtarResourceService.updateResourceUsers(users).pipe(
          switchMap((users) => of(updateResourceUsersResolved({ users }))),
          tap(() => (callback ? callback() : undefined)),
          catchError((e) => [])
        )
      )
    )
  );

  deleteResourceUsers = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteResourceUsers),
      switchMap(({ ids, callback }) =>
        this.ishtarResourceService.deleteResourceUsers(ids).pipe(
          switchMap((ids) => of(deleteResourceUsersResolved({ ids }))),
          tap(() => (callback ? callback() : undefined)),
          catchError((e) => [])
        )
      )
    )
  );

  getTimeSorts = createEffect(() =>
    this.actions$.pipe(
      ofType(getTimeSorts),
      switchMap(({ callback }) =>
        this.ishtarResourceService.getTimeSorts().pipe(
          switchMap((timeSorts) => [
            getTimeSortsResolved({
              timeSorts,
            }),
          ]),
          tap(() => (callback ? callback() : undefined)),
          catchError((e) => [])
        )
      )
    )
  );
}
