import { BaseEntity } from "processdelight-angular-components";
import { TimeSortType } from "../../enums/time-sort.type";

export class TimeSort extends BaseEntity {
  sort!: string;
  type!: TimeSortType;

  constructor(obj: Partial<TimeSort>) {
    super(obj);
    Object.assign(this, obj);
  }
}
