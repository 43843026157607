import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {
  ContextMenuAction,
  TranslationFile,
  TranslationService,
} from 'processdelight-angular-components';
import { Subject, takeUntil } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { CoreModule } from 'src/app/core.module';
import { AppConfig } from 'src/app/core/models/app.config';
import { ResourceFacade } from 'src/app/core/store/resource/resource.facade';

@Component({
  selector: 'app-color-settings-popup',
  standalone: true,
  imports: [CoreModule],
  templateUrl: './gantt-settings-popup.component.html',
  styleUrls: ['./gantt-settings-popup.component.scss'],
})
export class GanttSettingsPopupComponent implements OnInit, OnDestroy {
  translations: any;

  iconAction: ContextMenuAction<unknown>[] = [
    new ContextMenuAction({
      label: 'Close',
      icon: 'close',
      iconOutline: true,
      action: () => this.close(),
    }),
  ];

  button = new ContextMenuAction({
    id: 'save',
    label: 'Save',
    icon: 'save',
    index: 0,
    disabled: false,
    action: () => this.saveConfig(),
  });

  get buttons() {
    return [new ContextMenuAction({ ...this.button, disabled: this.loading })];
  }

  pages: ContextMenuAction<unknown>[] = [
    {
      id: 'colors',
      label: 'Colors',
      icon: 'palette',
      index: 0,
      action: () => this.selectPage('colors'),
    },
  ];

  navColor = AppComponent.navColor;
  navContrast = AppComponent.navContrast;

  activePage: ContextMenuAction<unknown> = this.pages[0];

  currentAppConfig: AppConfig | undefined;

  constructor(
    private dialog: MatDialogRef<GanttSettingsPopupComponent>,
    private resFac: ResourceFacade,
    private translate: TranslationService
  ) {
    this.translations = translate.translations;
  }

  ngOnInit(): void {
    this.resFac.appConfig$.pipe(takeUntil(this.destroy$)).subscribe((conf) => {
      this.currentAppConfig = new AppConfig({ ...conf });
    });
  }

  selectPage(id: string) {
    this.activePage = this.pages.find((p) => p.id === id)!;
  }

  isSelected(id: string) {
    return this.activePage.id === id;
  }

  getBackgroundColor(id: string) {
    return this.isSelected(id) ? this.navColor.replace(/\s/g, '') : 'white';
  }

  getTextColor(id: string) {
    return this.isSelected(id) ? this.navContrast.replace(/\s/g, '') : 'black';
  }

  getIconColor(id: string) {
    return this.isSelected(id) ? 'white' : 'black';
  }

  get presetColors() {
    return this.currentAppConfig?.presetColors.map((c) => c.value) || [];
  }

  loading = false;
  saveConfig() {
    this.loading = true;
    this.resFac.saveAppConfig(
      this.currentAppConfig!,
      () => (this.loading = false)
    );
  }

  close() {
    this.dialog.close();
  }

  destroy$ = new Subject<void>();
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
