import { Action, createReducer, on } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { Project } from '../../models/task/project';
import { ProjectMVP } from '../../models/task/projectMVP';
import { Task } from '../../models/task/task';
import { WorkRegime } from '../../models/ishtar365/workregime';
import {
  getProjectMVPsResolved,
  getProjectsResolved,
  getResourceCalculationResolved,
  getTasksResolved,
  getWorkRegimesResolved,
} from './task.action';

export const featureSlice = 'IshtarTask';

export interface State {
  tasks: Task[];
  projects: Project[];
  mvps: ProjectMVP[];
  regimes: WorkRegime[];
}
const defaultState: State = {
  tasks: [],
  projects: [],
  mvps: [],
  regimes: []
};

export function Reducer(state: State | undefined, action: Action) {
  return taskReducer(state, action);
}

export const initialState: State = defaultState;

export const taskState = (state: AppState) => state.coreFeature.IshtarTask;

export const taskReducer = createReducer(
  initialState,
  on(getResourceCalculationResolved, (state, { tasks, projects }) => {
    return {
      ...state,
      tasks: tasks,
      projects: projects,
    };
  }),
  on(getTasksResolved, (state, { tasks }) => {
    return {
      ...state,
      tasks: tasks,
    };
  }),
  on(getProjectsResolved, (state, { projects }) => {
    return {
      ...state,
      projects: projects,
    };
  }),
  on(getWorkRegimesResolved, (state, { regimes }) => {
    return {
      ...state,
      regimes: regimes,
    };
  }),
  on(getProjectMVPsResolved, (state, { mvps }) => ({
    ...state,
    mvps: mvps
  })),
);
