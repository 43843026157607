import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { map, Subject } from 'rxjs';
import { AppState } from 'src/app/app.reducer';
import { resourceThingState } from './resource.reducer';
import {
  getResourceThings,
  addResourceThings,
  removeResourceThings,
  updateResourceThings,
  addResourceFunctions,
  getResourceFunctions,
  removeResourceFunctions,
  updateResourceFunctions,
  getResourceUsers,
  addResourceUsers,
  updateResourceUsers,
  deleteResourceUsers,
  getResources,
  addResources,
  updateResourcesForTask,
  removeResources,
  updateAppConfig,
  getAppConfig,
  getInitialLoad,
  getAllResourceThings,
} from './resource.actions';
import { ResourceThing } from '../../models/resource/resourceThing';
import { ResourceFunction } from '../../models/resource/resourceFunction';
import { ResourceUser } from '../../models/resource/resourceUser';
import { Resource } from '../../models/resource/resource';
import { AppConfig } from '../../models/app.config';
import { Filter } from 'processdelight-angular-components';

@Injectable({
  providedIn: 'root',
})
export class ResourceFacade {
  appConfig$ = this._store.pipe(
    select(resourceThingState),
    map((state) => state.appConfig)
  );
  resourceThings$ = this._store.pipe(
    select(resourceThingState),
    map((state) => state.resourceThings)
  );
  resourceFunctions$ = this._store.pipe(
    select(resourceThingState),
    map((state) => state.resourceFunctions)
  );

  // TODO: remove empty resourceUser objects from db
  resourceUsers$ = this._store.pipe(
    select(resourceThingState),
    map((state) => state.resourceUsers.filter((f) => !!f.userId))
  );

  resources$ = this._store.pipe(
    select(resourceThingState),
    map((state) => state.resources)
  );

  timeSorts = this._store.pipe(
    select(resourceThingState),
    map((state) => state.timeSorts)
  )

  totalRecordCount$ = this._store.pipe(
    select(resourceThingState),
    map((state) => state.totalRecordCount)
  )

  constructor(private _store: Store<AppState>) {}

  getAppConfig(callback?: () => void): void {
    this._store.dispatch(
      getAppConfig({
        callback,
      })
    );
  }

  getInitialLoad() {
    const subject = new Subject<void>();
    this._store.dispatch(
      getInitialLoad({
        callback: () => {
          subject.next();
          subject.complete();
        },
      })
    );
    return subject.asObservable();
  }

  saveAppConfig(config: AppConfig, callback?: () => void) {
    this._store.dispatch(
      updateAppConfig({
        config,
        callback,
      })
    );
  }

  getResources$(callback?: () => void) {
    const subject = new Subject<void>();
    this._store.dispatch(
      getResources({
        callback: () => {
          callback ? callback() : undefined;
          subject.next();
          subject.complete();
        },
      })
    );
    return subject.asObservable();
  }

  addResources(resources: Resource[], callback?: () => void) {
    if (resources.length === 0) {
      if (callback) callback();
      return;
    }
    this._store.dispatch(addResources({ resources, callback }));
  }

  updateResourcesForTask(taskId: string, resources: Resource[], callback?: () => void) {
    if (resources.length === 0) {
      if (callback) callback();
      return;
    }
    this._store.dispatch(updateResourcesForTask({ taskId, resources, callback }));
  }

  deleteResources(ids: string[], callback?: () => void) {
    if (ids.length === 0) {
      if (callback) callback();
      return;
    }
    this._store.dispatch(removeResources({ ids, callback }));
  }

  getAllResourceThings$() {
    const subject = new Subject<ResourceThing[]>();
    this._store.dispatch(
      getAllResourceThings({
        callback: (resourceThings) => {
          subject.next(resourceThings);
          subject.complete();
        },
        errorCallback: (error) => {
          subject.error(error);
        },
      })
    );
    return subject.asObservable();
  }

  getResourceThings$(
    orderBy: string,
    direction: string,
    filters: Filter[],
    pageSize: number,
    page: number,
    resetPaging: boolean
  ) {
    const subject = new Subject<ResourceThing[]>();
    this._store.dispatch(
      getResourceThings({
        orderBy,
        direction,
        filters,
        pageSize,
        page,
        resetPaging,
        callback: (resourceThings) => {
          subject.next(resourceThings);
          subject.complete();
        },
        errorCallback: (error) => {
          subject.error(error);
        },
      })
    );
    return subject.asObservable();
  }

  addResourceThings(
    resourceThings: ResourceThing[],
    callback?: (addedResourceThings: ResourceThing[]) => void
  ) {
    this._store.dispatch(addResourceThings({ resourceThings, callback }));
  }

  removeResourceThings(ids: string[]) {
    const subject = new Subject<void>();
    this._store.dispatch(
      removeResourceThings({
        ids,
        callback: () => {
          subject.next();
          subject.complete();
        },
      })
    );
    return subject.asObservable();
  }

  updateResourceThings(resourceThings: ResourceThing[], callback?: () => void) {
    this._store.dispatch(updateResourceThings({ resourceThings, callback }));
  }

  getResourceFunctions$() {
    const subject = new Subject<void>();
    this._store.dispatch(
      getResourceFunctions({
        callback: () => {
          subject.next();
          subject.complete();
        },
      })
    );
    return subject.asObservable();
  }

  addResourceFunctions(
    resourceFunctions: ResourceFunction[],
    callback?: () => void
  ) {
    this._store.dispatch(addResourceFunctions({ resourceFunctions, callback }));
  }

  removeResourceFunction(ids: string[], callback?: () => void) {
    this._store.dispatch(removeResourceFunctions({ ids, callback }));
  }

  updateResourceFunctions(
    resourceFunctions: ResourceFunction[],
    callback?: () => void
  ) {
    this._store.dispatch(
      updateResourceFunctions({ resourceFunctions, callback })
    );
  }

  getResourceUsers(filters: Filter[], callback?: () => void) {
    const subject = new Subject<void>();
    this._store.dispatch(
      getResourceUsers({
        filters,
        callback: () => {
          subject.next();
          subject.complete();
          callback ? callback() : undefined;
        },
      })
    );
    return subject;
  }

  addResourceUsers(users: ResourceUser[], callback?: () => void) {
    this._store.dispatch(addResourceUsers({ users, callback }));
  }

  updateResourceUsers(users: ResourceUser[], callback?: () => void) {
    this._store.dispatch(updateResourceUsers({ users, callback }));
  }

  deleteResourceUsers(ids: string[], callback?: () => void) {
    this._store.dispatch(deleteResourceUsers({ ids, callback }));
  }
}
