import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { filter, map, Subject } from 'rxjs';
import { AppState } from 'src/app/app.reducer';
import { taskState } from '../task/task.reducer';
import {
  getProjects,
  getProjectMVPs,
  getResourceCalculation,
  getTasks,
  getWorkRegimes,
} from './task.action';
import { Task } from '../../models/task/task';

@Injectable({
  providedIn: 'root',
})
export class TaskFacade {
  tasks$ = this._store.pipe(
    select(taskState),
    map((state) => state.tasks)
  );

  tasksFlat$ = this._store.pipe(
    select(taskState),
    map((state) => state.tasks),
    filter((tasks) => !!tasks),
    map((tasks) => {
      const flatTasks: Task[] = [];
      tasks?.forEach((task) => {
        flatTasks.push(task);
        task.subTasks.forEach((subtask) => {
          flatTasks.push(subtask);
        });
      });
      return flatTasks;
    })
  );

  projects$ = this._store.pipe(
    select(taskState),
    map((state) => state.projects)
  );

  workRegimes$ = this._store.pipe(
    select(taskState),
    map((state) => state.regimes)
  );

  constructor(private _store: Store<AppState>) {}

  getResourceCalculation(
    assignedToMe: boolean,
    callback?: () => void,
    mockDate?: string
  ) {
    const subject = new Subject<void>();
    this._store.dispatch(
      getResourceCalculation({
        assignedToMe,
        callback: () => {
          subject.next();
          subject.complete();
          callback ? callback() : undefined;
        },
        mockDate,
      })
    );
    return subject.asObservable();
  }

  getTasks(callback?: () => void) {
    const subject = new Subject<void>();
    this._store.dispatch(
      getTasks({
        callback: () => {
          callback ? callback() : undefined;
          subject.next();
          subject.complete();
        },
      })
    );
    return subject.asObservable();
  }

  getProjects(callback?: () => void) {
    const subject = new Subject<void>();
    this._store.dispatch(
      getProjects({
        callback: () => {
          callback ? callback() : undefined;
          subject.next();
          subject.complete();
        },
      })
    );
    return subject.asObservable();
  }

  getProjectMVPs(callback?: () => void) {
    const subject = new Subject<void>();
    this._store.dispatch(
      getProjectMVPs({
        callback: () => {
          callback ? callback() : undefined;
          subject.next();
          subject.complete();
        },
      })
    );
    return subject.asObservable();
  }

  getWorkRegimes(callback?: () => void) {
    const subject = new Subject<void>();
    this._store.dispatch(
      getWorkRegimes({
        callback: () => {
          callback ? callback() : undefined;
          subject.next();
          subject.complete();
        },
      })
    );
    return subject.asObservable();
  }
}
