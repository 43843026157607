import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, of, switchMap, tap } from 'rxjs';
import { IshtarResourcePlanningService } from '../../services/resourceplanning.service';
import {
  getResourceCalculation,
  getProjects,
  getProjectMVPs,
  getProjectMVPsResolved,
  getProjectsResolved,
  getTasks,
  getTasksResolved,
  getWorkRegimes,
  getWorkRegimesResolved,
  getResourceCalculationResolved,
} from './task.action';
import { ResourceFacade } from '../resource/resource.facade';

@Injectable({ providedIn: 'root' })
export class TaskEffects {
  constructor(
    private actions$: Actions,
    private ishtarResourceService: IshtarResourcePlanningService,
    private resourceFacade: ResourceFacade
  ) {}

  getResourceCalculation = createEffect(() =>
    this.actions$.pipe(
      ofType(getResourceCalculation),
      switchMap(({ callback, assignedToMe, mockDate }) =>
        this.ishtarResourceService.getResourceCalculation(assignedToMe, mockDate).pipe(
          switchMap((data) => of(getResourceCalculationResolved(data))),
          tap(() => (callback ? callback() : undefined)),
          catchError((e) => [])
        )
      )
    )
  );

  getTasks = createEffect(() =>
    this.actions$.pipe(
      ofType(getTasks),
      switchMap(({ callback }) =>
        this.ishtarResourceService.getTasks().pipe(
          switchMap((tasks) =>
            of(
              getTasksResolved({
                tasks,
              })
            )
          ),
          tap(() => (callback ? callback() : undefined)),
          catchError((e) => [])
        )
      )
    )
  );

  getProjects = createEffect(() =>
    this.actions$.pipe(
      ofType(getProjects),
      switchMap(({ callback }) =>
        this.ishtarResourceService.getProjects().pipe(
          switchMap((projects) =>
            of(
              getProjectsResolved({
                projects,
              })
            )
          ),
          tap(() => (callback ? callback() : undefined)),
          catchError((e) => [])
        )
      )
    )
  );

  getProjectMVPs = createEffect(() =>
    this.actions$.pipe(
      ofType(getProjectMVPs),
      switchMap(({ callback }) =>
        this.ishtarResourceService.getProjectMVPs().pipe(
          switchMap((mvps) =>
            of(
              getProjectMVPsResolved({
                mvps,
              })
            )
          ),
          tap(() => (callback ? callback() : undefined)),
          catchError((e) => [])
        )
      )
    )
  );

  workRegimes = createEffect(() =>
    this.actions$.pipe(
      ofType(getWorkRegimes),
      switchMap(({ callback }) =>
        this.ishtarResourceService.getWorkRegimes().pipe(
          switchMap((regimes) =>
            of(
              getWorkRegimesResolved({
                regimes,
              })
            )
          ),
          tap(() => (callback ? callback() : undefined)),
          catchError((e) => [])
        )
      )
    )
  );
}
