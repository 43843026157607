import { Injectable } from '@angular/core';
import { ContextMenuAction, SideBarAction } from 'processdelight-angular-components';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ActionBarService {
  sideBarActions = new Subject<SideBarAction[]>();
  navActions = new Subject<ContextMenuAction<unknown>[]>();
  iconActions = new Subject<ContextMenuAction<unknown>[]>();
  initialSearchValue: string | undefined;
  searchValue = new Subject<string>();
}
