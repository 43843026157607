import { BaseEntity, GroupUser } from 'processdelight-angular-components';
import { DateTime } from 'luxon';
import { IProjectTask } from './project-task.interface';
import { Task } from './task';
import { GroupUserEntity } from '../group-user-entity';

export class Project extends BaseEntity implements IProjectTask {
  projectName!: string;
  projectId?: string;
  parentId?: string;
  clientId?: string;
  statusId?: string;
  description?: string;
  preStudy?: boolean;
  calculateProgress?: boolean;
  isTemplate?: boolean;
  startDate?: DateTime;
  deadline?: DateTime;
  projectTypeId?: string;
  projectVersion?: string;
  projectChannel?: string;
  progress?: number;
  productOwnerId?: string;
  productOwner?: GroupUserEntity;

  get title(): string {
    return this.projectName;
  }

  get number(): number | undefined {
    return this.projectId ? Number(this.projectId) : undefined;
  }

  subProjects: Project[] = [];
  tasks: Task[] = [];

  calculatedStartDate?: DateTime;
  calculatedEndDate?: DateTime;

  expandable = true;
  level = 0;
  class: 'project' | 'task' | 'projectTask' = 'project';

  dependentOn?: string;
  dependencyCompletionValue?: number;

  get subElements(): IProjectTask[] {
    return (
      [...this.subProjects].sort(
        (a, b) => Number(a?.projectId || 0) - Number(b?.projectId || 0)
      ) as IProjectTask[]
    ).concat(this.tasks);
  }

  get users(): GroupUserEntity[] {
    return this.productOwner ? [this.productOwner] : [];
  }

  subElementFilter?: (x: IProjectTask) => boolean;

  filterNode(search: string): boolean {
    return (
      this.title.toLowerCase().includes(search.toLowerCase()) 
        || this.productOwner?.displayName?.toLowerCase()
          .includes(search.toLowerCase()) 
        || this.users.some((u) => 
          u.displayName?.toLowerCase().includes(search.toLowerCase())
        )
        || this.projectId?.includes(search)
        || false
    );
  }

  containsUser(userId: string): boolean {
    return (
      this.productOwner?.id === userId 
        || this.users.some((u) => u.id === userId) 
        || this.subElements?.some((x) => x.containsUser(userId))
        || false
    );
  }

  constructor(obj: Partial<Project>) {
    super(obj);
    Object.assign(this, obj);

    if (obj['tasks']) {
      this.tasks = obj['tasks']
        .map((t) => new Task({ ...t, subElementFilter: this.subElementFilter }))
        .sort((a, b) => (a?.number || 0) - (b?.number || 0))
        .filter(this.subElementFilter ? this.subElementFilter : (x) => true);
    }

    this.subProjects = this.subProjects
      .map(
        (p) => new Project({ ...p, subElementFilter: this.subElementFilter })
      )
      .filter(this.subElementFilter ? this.subElementFilter : (x) => true);
    this.expandable = this.subProjects.length > 0 || this.tasks.length > 0;
  }
}
