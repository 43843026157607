<div class="dialogContainer">
  <h3 mat-dialog-title>{{translations.unsavedChanges}}</h3>
  <mat-icon class="closeButtonIcon" (click)="cancel()" mat-dialog-close
    >close</mat-icon
  >
  <div
    mat-dialog-content
    class="p-3 d-flex flex-column justify-content-center align-items-center"
  >
    <p>{{translations.doYouWantToExitWithoutSavingUnsavedChanges}}</p>
    <div class="buttons">
      <button
        mat-raised-button
        mat-dialog-close
        class="menuButton"
        color="primary"
        (click)="cancel()"
      >
        <span>{{translations.stayHere}}</span>
      </button>

      <button mat-raised-button (click)="confirm()">
        <span>{{translations.continue}}</span>
      </button>
    </div>
  </div>
</div>
